import svg4everybody from 'svg4everybody';
import zenscroll from 'zenscroll';
import debounce from 'lodash.debounce';

window.svg4everybody = svg4everybody;
window.zenscroll = zenscroll;
window.LodashDebounce = debounce;

(() => {
  'use strict';

  const module = {
    init() {
      this.load_module('module-region','js');

      if ( document.querySelector('#header') ) {
        zenscroll.setup(null, document.querySelector('#header').offsetHeight);
      }

      this.slider();
      this.clickout();
      this.toggler();
      this.scrollbar();
      this.modal();
      this.tooltip();
      this.gallery();

      this.form();
      this.input_file();

      this.hsticky();

      this.product_card();
      this.catalog_menu_dropdown();
      this.catalog_list_dropdown();

      this.offer();

      this.yamap();

      this.gtag();
      this.telegram();

      this.block_scroll(false);
      document.documentElement.classList.remove('is-page-loading');
    },

    block_scroll(status = false) {
      if (!status) {
        document.documentElement.classList.remove('is-no-scroll');
        return;
      }

      document.documentElement.classList.add('is-no-scroll');
    },

    load_module(name = '', type = 'js', position = 'afterend') {
      if ( !name ) return;

      if ( type == 'js' ) {
        const el = document.createElement('script');
        el.setAttribute('src', `/assets/themes/mytheme/assets/js/${name}.js`);
        el.setAttribute('defer', 'defer');
        const target = document.querySelector('script#mytheme-js');
        target.insertBefore(el,null);
      }

      if ( type == 'css' ) {
        const el = document.createElement('link');
        el.setAttribute('rel','stylesheet');
        el.setAttribute('type','text/css');
        el.setAttribute('media','all');
        el.setAttribute('href',`/assets/themes/mytheme/assets/css/${name}.css`);
        const target = document.querySelector('link#mytheme-app-css');
        target.insertAdjacentElement(position,el);
      }
    },

    clickout() {
      if ( document.querySelector('.js-clickout') ) {
        this.load_module('module-clickout','js');
      }
    },

    toggler() {
      if ( document.querySelector('[data-toggle],[data-toggle-group],[data-toggle-accordion]') ) {
        this.load_module('module-toggler','js');
      }
    },

    scrollbar() {
      if ( document.querySelector('.js-perfectscroll') ) {
        this.load_module('perfect-scrollbar.min','css','beforebegin');
        this.load_module('module-scrollbar','js');
      }
    },

    hsticky() {
      if ( document.querySelector('#hsticky') ) {
        this.load_module('module-hsticky','js');
      }
    },

    modal() {
      if ( document.querySelector('[data-unimodal-open]') ) {
        this.load_module('module-modal','js');
      }
    },

    tooltip() {
      if ( document.querySelector('.js-tooltip') ) {
        this.load_module('module-tooltip','js');
      }
    },

    gallery() {
      if ( document.querySelector('.js-gallery') ) {
        this.load_module('lightgallery.min','css','beforebegin');
        this.load_module('module-gallery','js');
      }
    },

    slider() {
      if ( document.querySelector('#sl-hero, .js-sl-product-card, .js-sl-cards, .js-sl-product-gallery') ) {
        // this.load_module('swiper-bundle.min','css','beforebegin');
        this.load_module('module-slider','js');
      }
    },

    form() {
      if ( document.querySelector('[data-bouncer]') ) {
        this.load_module('module-form','js');
      }
    },

    input_file() {
      if ( document.querySelector('.js-file-upload') ) {
        this.load_module('module-input-file','js');
      }
    },

    product_card() {
      if (window.innerWidth <= 1024) return;

      const elems = document.querySelectorAll('.js-product-card');
      if (!elems.length) return;

      elems.forEach(el => {
        el.style.height = '';
      });

      elems.forEach(el => {
        el.style.height = `${el.offsetHeight}px`;
        el.classList.add('is-loaded');
      });
    },

    catalog_menu_dropdown() {
      if ( document.querySelector('#catalog-dropdown') ) {
        this.load_module('module-catalog-menu-dropdown','js');
      }
    },

    catalog_list_dropdown() {
      if (window.innerWidth < 768) return;

      if ( document.querySelector('#catalog-list') ) {
        this.load_module('module-catalog-list-dropdown','js');
      }
    },

    offer() {
      if ( document.querySelector('.js-main.js-offer') ) {
        this.load_module('module-offer','js');
      }
    },

    gtag() {
      if ( document.querySelector('.js-gtag-view-item-list', '#goods-cards', '.js-gtag-view-item') ) {
        this.load_module('module-gtag','js');
      }
    },

    telegram() {
      if ( document.querySelector('.js-post-content') ) {
        this.load_module('module-telegram','js');
      }
    },

    yamap() {
      if ( document.querySelector('.js-map') ) {
        this.load_module('module-yamap','js');
      }
    },
  };

  module.init();

  window._App = module;

  // document.addEventListener('DOMContentLoaded', () => {
  //   module.init();
  // });

  window.onbeforeunload = () => {
    document.documentElement.classList.add('is-page-loading');
  };
})();
